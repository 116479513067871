(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/create-game/assets/javascripts/hooks/use-draws.js') >= 0) return;  svs.modules.push('/components/marketplace/create-game/assets/javascripts/hooks/use-draws.js');

'use strict';

const _excluded = ["loading", "lastFetch", "drawsError"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  resolveProductId
} = svs.utils.products;
const {
  sortDraws
} = svs.components.marketplace.createGame.helpers;
const {
  fetchGame,
  selectDraw
} = svs.components.marketplaceData.createGame.actions;
const {
  BUY_STOP_DIFF
} = svs.components.marketplaceData.createGame.constants;
const {
  fetchAllDrawsAction,
  fetchDrawsByProductAction
} = svs.components.marketplaceData.draws.actions;
const {
  DRAWSTATE_OPEN,
  DRAWSTATE_DEFINED
} = svs.components.marketplaceData.constants;
const {
  getDraw
} = svs.components.marketplaceData.selectors.draws;
const {
  RACING
} = svs.utils.products.productIds;
const DrawFetchState = Object.freeze({
  ERROR: 'draw-error',
  LOADING: 'draw-loading',
  OK: 'draw-ok'
});
const TWO_MINUTES = 120000;

const _fetchDrawsByProductAction = (dispatch, resolvedProductId) => {
  dispatch(fetchDrawsByProductAction(resolvedProductId));
};
const _fetchAllDrawAction = (dispatch, availableProducts) => {
  dispatch(fetchAllDrawsAction(availableProducts));
};
const _fetchGameAction = (dispatch, groupId, containerId) => {
  dispatch(fetchGame(groupId, containerId));
};
const _selectDraw = (dispatch, drawNumber) => {
  dispatch(selectDraw(drawNumber));
};
const useDraws = _ref => {
  let {
    activatedDrawId,
    containerId,
    groupId,
    forceFetch = false
  } = _ref;
  const dispatch = useDispatch();
  const {
    availableProducts,
    loadingGame,
    selectedDraw,
    selectedProduct
  } = useSelector(state => state.CreateGame);
  const _useSelector = useSelector(state => state.Draws.draws),
    {
      loading,
      lastFetch,
      drawsError: error
    } = _useSelector,
    draws = _objectWithoutProperties(_useSelector, _excluded);
  const {
    loadingSingle,
    drawLoading,
    drawEvents
  } = useSelector(state => state.Draws);
  const draw = useSelector(state => getDraw(state, {
    drawNumber: selectedDraw,
    productName: selectedProduct
  }));
  const productDraws = (draws[selectedProduct] || []).filter(_ref2 => {
    let {
      drawStateId
    } = _ref2;
    return drawStateId === DRAWSTATE_OPEN || drawStateId === DRAWSTATE_DEFINED;
  }).map(productDraw => _objectSpread(_objectSpread({}, productDraw), {}, {
    regCloseTime: new Date(productDraw.regCloseTime)
  }));
  let shouldFetchNewDrawData = true;
  if (lastFetch) {
    const lastFetchTime = new Date(lastFetch).getTime();
    shouldFetchNewDrawData = lastFetchTime + TWO_MINUTES < Date.now();
  }
  useEffect(() => {
    if (activatedDrawId) {
      return svs.components.marketplace.createGame.hooks._fetchGameAction(dispatch, groupId, containerId);
    }
  }, [activatedDrawId, containerId, dispatch, groupId]);
  useEffect(() => {
    if (!activatedDrawId && selectedProduct && !loading && (shouldFetchNewDrawData || forceFetch)) {
      var _drawLoading$selected;
      const productId = resolveProductId(selectedProduct);
      const shouldFetchProductDraws = !drawEvents[selectedProduct] && productId !== RACING;
      if (shouldFetchProductDraws && !((_drawLoading$selected = drawLoading[selectedProduct]) !== null && _drawLoading$selected !== void 0 && _drawLoading$selected.loading)) {
        return svs.components.marketplace.createGame.hooks._fetchDrawsByProductAction(dispatch, productId);
      }
    }
  }, [activatedDrawId, dispatch, drawEvents, drawLoading, forceFetch, loading, selectedProduct, shouldFetchNewDrawData]);
  useEffect(() => {
    if (!activatedDrawId && !selectedProduct && !loading && shouldFetchNewDrawData) {
      return svs.components.marketplace.createGame.hooks._fetchAllDrawAction(dispatch, availableProducts);
    }
  }, [activatedDrawId, availableProducts, dispatch, loading, selectedProduct, shouldFetchNewDrawData]);
  useEffect(() => {
    if (!activatedDrawId) {
      if (productDraws.filter(_ref3 => {
        let {
          drawNumber
        } = _ref3;
        return drawNumber === selectedDraw;
      }).length === 0) {
        if (productDraws.length) {
          const minimumBufferInMs = BUY_STOP_DIFF * 60 * 1000;
          const latestClosingTime = new Date(Date.now() + minimumBufferInMs);
          const availableDraw = productDraws.find(productDraw => new Date(productDraw.regCloseTime) > latestClosingTime);
          if (availableDraw) {
            svs.components.marketplace.createGame.hooks._selectDraw(dispatch, availableDraw.drawNumber);
          }
        }
      }
    }
  }, [activatedDrawId, selectedDraw, dispatch, productDraws]);
  let drawState = DrawFetchState.LOADING;
  if (error) {
    drawState = DrawFetchState.ERROR;
  } else if (!loading && !loadingSingle && !loadingGame) {
    drawState = DrawFetchState.OK;
  }
  return {
    error,
    draw,
    DrawFetchState,
    draws: sortDraws(draws || {}),
    drawState,
    productDraws
  };
};

setGlobal('svs.components.marketplace.createGame.hooks', {
  _fetchAllDrawAction,
  _fetchDrawsByProductAction,
  _fetchGameAction,
  _selectDraw,
  useDraws
});

 })(window);